const body = document.querySelector("body");
if (!body.classList.contains("elementor-editor-wp-page")) {
  const accordionBtns = document.querySelectorAll(".accordion__btn");
  if (accordionBtns) {
    accordionBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        const parent = btn.parentNode;
        const children = parent.childNodes;
        const childrenFixed = [];
        const btnIndex = Array.prototype.indexOf.call(parent.children, btn);
        let nextBtn;

        btn.classList.toggle("accordion__btn--opened");

        children.forEach((child) => {
          if (child.nodeName === "SECTION" || child.nodeName === "DIV")
            childrenFixed.push(child);
        });

        let breakLoop = false;
        childrenFixed.slice(btnIndex + 1).forEach((item) => {
          if (!breakLoop) {
            if (item.classList.contains("accordion__btn")) {
              nextBtn = item;
              breakLoop = true;
            }
          }
        });

        const nextBtnIndex = Array.prototype.indexOf.call(
          parent.children,
          nextBtn
        );

        if (nextBtnIndex !== -1) {
          childrenFixed.slice(btnIndex + 1, nextBtnIndex).forEach((child) => {
            child.classList.toggle("accordion__item--active");
          });
        } else {
          childrenFixed.slice(btnIndex + 1).forEach((child) => {
            child.classList.toggle("accordion__item--active");
          });
        }
      });
    });
  }
  const accordions = document.querySelectorAll(".accordion");
  if (accordions) {
    accordions.forEach((accordion) => {
      const accordionDiv = accordion.childNodes[1].childNodes[1];
      if (accordionDiv.classList.contains("elementor-widget-wrap")) {
        const children = accordionDiv.childNodes;
        const childrenFixed = [];

        children.forEach((child) => {
          if (child.nodeName !== "#text") {
            if (
              child.classList.contains("accordion__btn") ||
              child.classList.contains("accordion__item")
            ) {
              childrenFixed.push(child);
            }
          }
        });

        const btns = [];
        let lastIndexToOpen;

        childrenFixed.forEach((child) => {
          if (child.classList.contains("accordion__btn")) btns.push(child);
        });

        btns[0].classList.add("accordion__btn--opened");

        if (btns.length === 1) {
          lastIndexToOpen = childrenFixed.length;
        } else if (btns.length > 1) {
          lastIndexToOpen = Array.prototype.indexOf.call(
            childrenFixed,
            btns[1]
          );
        }

        childrenFixed.slice(0, lastIndexToOpen).forEach((child) => {
          child.classList.add("accordion__item--active");
        });
      }
    });
  }
}

const submenus = document.querySelectorAll(".sub-menu");

const descriptions = [];

if (submenus) {
  descriptions.push(
    document.querySelector(".submenu-description-first").textContent
  );
  descriptions.push(
    document.querySelector(".submenu-description-second").textContent
  );
  descriptions.push(
    document.querySelector(".submenu-description-third").textContent
  );
  submenus.forEach((submenu) => {
    const submenuContainer = document.createElement("div");
    submenuContainer.classList.add("submenu-container");

    const linksContainer = document.createElement("div");
    linksContainer.classList.add("submenu-links-container");

    const descriptionContainer = document.createElement("div");
    descriptionContainer.classList.add("submenu-description-container");

    submenu.insertBefore(submenuContainer, submenu.children[0]);
    submenuContainer.insertBefore(linksContainer, submenuContainer.children[0]);
    submenuContainer.insertBefore(
      descriptionContainer,
      submenuContainer.children[0]
    );

    const submenuItems = submenu.childNodes;
    submenuItems.forEach((item) => {
      if (item.nodeName === "LI") {
        linksContainer.appendChild(item);
      }
    });

    const descriptionHeading = document.createElement("h3");
    const descriptionText = document.createElement("p");
    const submenuParentText = submenu.parentNode.childNodes[0].textContent;

    if (submenuParentText === "Zadaszenia") {
      descriptionText.textContent = descriptions[0];
      descriptionHeading.textContent = "Zadaszenia";
    } else if (submenuParentText === "Letni ogród") {
      descriptionText.textContent = descriptions[1];
      descriptionHeading.textContent = "Letni ogród";
    } else if (submenuParentText === "Akcesoria") {
      descriptionText.textContent = descriptions[2];
      descriptionHeading.textContent = "Akcesoria";
    }

    descriptionContainer.insertBefore(
      descriptionText,
      descriptionContainer.children[0]
    );
    descriptionContainer.insertBefore(
      descriptionHeading,
      descriptionContainer.children[0]
    );
  });

  const initDescriptions = document.querySelector(".init-submenu-descriptions");
  if (initDescriptions) {
    initDescriptions.remove();
  }
}

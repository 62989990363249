//js
if (!global._babelPolyfill) {
  require("babel-polyfill");
}
require("./js/slider");
require("./js/helper");
require("./js/navigation");
require("./js/submenu");
require("./js/forminatorRow");
require("./js/accordion");
require("./js/fixHref");
require("./js/main");

//fonts
// require('./fonts/font/stylesheet.css');
require("./fonts/fonts.css");

// css
import "normalize.css";
require("./sass/style.scss");

const fixHrefs = document.querySelectorAll(".fix-href");
if (fixHrefs) {
  fixHrefs.forEach((item) => {
    const contentBox = item.childNodes[1].childNodes[1].childNodes[3];
    const children = contentBox.childNodes;

    const anchor = document.createElement("a");
    let textContent;
    let classList;
    let href;
    let paragraph;

    children.forEach((el) => {
      if (el.nodeName === "P") {
        textContent = el.textContent;
        classList = el.classList.value;
        paragraph = el;
      }
      if (el.nodeName === "H3") {
        href = el.childNodes[1].href;
      }
    });

    anchor.textContent = textContent;
    anchor.className = classList;
    anchor.href = href;

    paragraph.remove();
    contentBox.appendChild(anchor);
  });
}
